import axios from 'axios';
import config from '@/vue.config';
import { makeFormData } from '@/plugins/helper';

const GET_ALL_PAYMENT_SYSTEMS =
    config.store.payment_systems.getAllPaymentSystems;
const CHANGE_PAYMENT_STATUS =
    config.store.payment_systems.changePaymentSystemsStatus;

const state = {
    paymentSystems: [],
};

const getters = {
    getPaymentSystems: state => state.paymentSystems,
};

const mutations = {
    setPaymentSystems: (state, payload) => (state.paymentSystems = payload),
};

const actions = {
    getAllPaymentSystems: async ({ commit }) => {
        try {
            const { data } = await axios.get(GET_ALL_PAYMENT_SYSTEMS);
            commit('setPaymentSystems', data.data);
        } catch (error) {
            console.log(error);
            commit('setPaymentSystems', []);
        }
    },

    changePaymentStatus: async ({}, payload) => {
        try {
            let formData = { ...payload };
            formData = makeFormData(formData);
            await axios.post(CHANGE_PAYMENT_STATUS, formData);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
