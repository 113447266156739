const USER_URL = 'https://devgate.invest.fcpirin.com/public/user/';
const AUTH_URL = 'https://devgate.invest.fcpirin.com/public/auth/';
const FINANCE_URL = 'https://devgate.invest.fcpirin.com/public/finances/';
const STARTUP_URL = 'https://devgate.invest.fcpirin.com/public/startup/';

export default {
    store: {
        user: {
            getUser: USER_URL + 'get-user?info=1',
            getUserInfo: USER_URL + 'get-user?info=1&settings=1',
            getUserStatuses: USER_URL + 'get-user-statuses',
            getUsersGroupBy: USER_URL + 'get-user?dashboard=1',
            getUserCountries: USER_URL + 'get-geo-country',
            getUserIdentificationTypes: USER_URL + 'get-identification-types',
            editProfile: USER_URL + 'edit-profile',
            changeUserStatus: USER_URL + 'change-user-status',
            resetFinances: USER_URL + 'return-investor-finances',
            getAllUsersExcel: USER_URL + 'get-all-users-excel',
        },
        shareholders: {
            getShareholders: FINANCE_URL + 'get-shareholders',
            getShareholderShares: FINANCE_URL + 'get-shareholders-shares',
            getAllShareholdersExcel: FINANCE_URL + 'get-shareholders-excel',
        },
        sales_manager: {
            addSalesManager: USER_URL + 'add-sales-manager',
            getSalesManagers: USER_URL + 'get-sales-managers',
            getManagerUsers: USER_URL + 'get-sales-manager-users',
        },
        auth: {
            login: AUTH_URL + 'login',
            logout: AUTH_URL + 'logout',
            identification: AUTH_URL + 'identification',
        },
        startup: {
            getPdfInvoice: STARTUP_URL + 'get-user-invoice-pdf',
            getStartup: STARTUP_URL + 'get-startup',
            getStartupStatus: STARTUP_URL + 'get-startup-statuses',
            getStartupPlacement: STARTUP_URL + 'get-startup?info=1',
            startupApprove: STARTUP_URL + 'startup-approve',
            startupDecline: STARTUP_URL + 'startup-decline',
            getIndustries: STARTUP_URL + 'get-industries',
        },
        finances: {
            getPdfDetails: FINANCE_URL + 'get-transaction-pdf',
            getAllUserTransactions: FINANCE_URL + 'get-all-users-transactions',
            getAllExcel: FINANCE_URL + 'get-all-users-transactions-excel',
        },
        reserved: {
            getReserved: FINANCE_URL + 'get-reserved-transactions?startup_id=1',
            approveReserved: STARTUP_URL + 'approve-reserve-shares-transaction',
            declineReserved: FINANCE_URL + 'decline-reserve-shares-transaction',
            getAllReservedExcel:
                FINANCE_URL + 'get-reserved-transactions-excel',
        },
        commissions: {
            getAllDiscounts: FINANCE_URL + 'get-all-discounts',
            getCommissionsPayment: FINANCE_URL + 'get-payment-stripe-settings',
            changePaymentFee: FINANCE_URL + 'change-payment-fee',
            createDiscount: FINANCE_URL + 'create-discount',
        },
        paper_shares: {
            getPaperShares: STARTUP_URL + 'get-all-certificates-info',
            getPaperCertificate: STARTUP_URL + 'get-certificate-pdf-by-id',
            getAllCertificatesExcel:
                STARTUP_URL + 'get-all-paper-certificates-excel',
        },
        packages_shares: {
            getPackagesShares: FINANCE_URL + 'get-all-share-packages-admin',
            createPackage: FINANCE_URL + 'create-share-package',
            changePackage: FINANCE_URL + 'change-package',
            deletePackage: FINANCE_URL + 'delete-package',
        },
        add_documents: {
            addCorporativeDocument: USER_URL + 'add-corporative-document',
            updateCorporativeDocument: USER_URL + 'update-corporative-document',
            deleteCorporativeDocument: USER_URL + 'delete-corporative-document',
            getAllDocumentsCategories:
                USER_URL + 'get-all-documents-categories?admin=true',
            getAllCorporativeDocuments:
                USER_URL + 'get-corporative-documents?type=1',
        },
        payment_systems: {
            getAllPaymentSystems: FINANCE_URL + 'get-all-payment-systems',
            changePaymentSystemsStatus:
                FINANCE_URL + 'change-payment-system-status',
        },
    },
    no_image:
        'https://dib-main.s3.eu-central-1.amazonaws.com/no-image-found-360x250.png',
};
